<template>
  <div class="overlay">
    <page-title>{{i18n.MENU.glass}}</page-title>
    <div class="page-width" v-if="model === ''">
      <div class="glass__empty">
        {{i18n.GLASS.SELECT_MODEL}}
      </div>
    </div>
    <div class="page-width" v-if="model !== ''">
<!--      <tabset :tabs="['Двері', 'Бокова частина']" @switchTab="toggleTab" :active="activeTab" />-->
      <div class="glass">
        <div class="glass__empty" v-if="items.length === 0">
          {{i18n.GLASS.NO_GLASSES}}
        </div>
        <label :class="['glass__item',
        {'glass__item--current': item.id === glass.id},
        {'glass__item--active': item.active === '1'}
        ]"
               v-for="item in items"
               @click.prevent="setGlass(item, item.id === glass.id || item.active === '0')"
               :key="`glass__${item.id}`">
            <span class="glass__bg">
              <img class="glass__img"
                   :src="item.image_png"
                   :alt="item.title">
            </span>
          <span class="glass__title">{{item.title}}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/page-title/page-title.vue'
  // import Tabset from '@/components/shared/tabset.vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import {API} from '@/constants/api'
  import {setCode} from '@/utils/code'

  export default {
    data() {
      return {
        activeTab: 0,
        items: []
      }
    },
    computed: {
      ...mapGetters('config', [
        'shape',
        'model',
        'glass',
        'color',
        'furniture',
        'options',
        'inox',
        'view',
        'mirrored',
        'glass',
      ]),
      ...mapGetters('session', [
        'i18n',
      ]),
    },
    components: {
      PageTitle,
      // Tabset,
    },
    metaInfo() {
      return {
        title: this.i18n.MENU.glass,
        meta: [
          { vmid: 'description', property: 'description', content: 'Cкло' },
          { vmid: 'og:title', property: 'og:title', content: 'Скло' },
          { vmid: 'og:description', property: 'og:description', content: 'Скло' },
        ],
      }
    },
    methods: {
      getGlasses() {
        const { shape, model } = this
        axios
          .get(API.GET_GLASS, {
            params: {
              shape: shape.id,
              model: model.id,
              type: this.activeTab === 0 ?
                'door' : 'side'
            }
          })
          .then(response => (this.items = response.data));
      },
      async setGlass(item, isActive) {
        if (isActive) {
          return false
        }
        let response;
        const {
          shape,
          model,
          // glass,
          color,
          furniture,
          options,
          inox,
          view,
          mirrored,
          black,
        } = this
        const moldingView = inox?.url.includes('molding') ? inox?.url.split('-')[1] : null

        try {
          response = await axios.get(API.GET_CODE_FROM_CONFIG, {
            params: {
              shape: shape.id,
              model: model.id,
              glass: item.id,
              colour_outside: color.outside.id,
              colour_inside: color.inside.id,
              furniture: furniture.id,
              options: options.id,
              inox: inox.id,
              molding: moldingView,
              view: view.id,
              mirrored: mirrored.id,
              black,
            }
          })
        } catch (e) {
          console.error(e)
        }
        await setCode({code: response.data.code, store: this.$store, router: this.$router})
        await this.$store.dispatch('config/CONFIG_SET_GLASS', item)
      },
      // toggleTab(idx) {
      //   this.activeTab = idx
      //   this.getGlasses()
      // },
    },
    created() {
      this.getGlasses()
    },
  }
</script>

<style lang="scss">
.page-width {
  padding: 30px;
}
.glass {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  &__item {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 10px;
    opacity: .5;
    &--active {
      opacity: 1;
      cursor: pointer;
    }
    &--active:hover,
    &--current {
      .glass__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #C70552;
          z-index: 2;
        }
      }
    }
    &--current {
      cursor: default;
    }
  }
  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 150%;
  }
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &__title {
    display: block;
    text-align: center;
    margin: 10px 0 0;
    font: 15px/18px "ProximaNovaRegular", sans-serif;
    color: #FFFFFF;
  }
  &__empty {
    color: #ffffff;
  }
}
</style>
